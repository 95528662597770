<template>
  <div class="password">
    <el-card class="box-card">
      <div slot="header" class="box-card-header">
        <h4>{{ $t('dc:修改密码') }}</h4>
      </div>
      <el-form
        :model="resetDateForm"
        ref="resetDateForm"
        :rules="rulesList"
        hide-required-asterisk
        label-width="170px"
        label-position="right"
        class="demo-dynamic"
        style="width: 580px; margin: 0 auto; margin-bottom: 0"
      >
        <el-form-item label-width="250px" :label="$t('dc:用户名字')" class="marginB38">
          <el-input
            :disabled="showName"
            v-model="resetDateForm.accountname"
            maxlength="100"
            show-word-limit
          ></el-input>
          <!-- <span v-show="showName" class="el-form-item__error fontS12">Please input valid format;</span> -->
        </el-form-item>
        <el-form-item
          label-width="250px"
          prop="password"
          :label="$t('dc:新密码')"
          class="marginB38"
        >
          <el-input
            show-password
            v-model="resetDateForm.password"
            maxlength="100"
            show-word-limit
          ></el-input>
          <span v-show="showPossword" class="el-form-item__error fontS12"
            >Only support number. capital and lower-case letter</span
          >
        </el-form-item>
        <el-form-item
          label-width="250px"
          prop="confirmPassword"
          :label="$t('dc:确认新密码')"
          class="marginB38"
        >
          <el-input
            show-password
            v-model="resetDateForm.confirmPassword"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="boxBtn">
        <el-button class="" @click="onCancel">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-black" type="primary" @click="submitForm('resetDateForm')">{{
          $t('dc:确认')
        }}</el-button>
      </div>
    </el-card>
    <passwordDialog
      :visible.sync="showDialog"
      :title="title"
      :content="content"
      @confrom="confrom"
    ></passwordDialog>
  </div>
</template>

<script>
import JSEncrypt from 'jsencrypt'
import { mapGetters, mapActions } from 'vuex'
import passwordDialog from '@/views/dialog/usuallyDialog'
import config from '@/api/config'
import createRsa from '@/utils/encryption'
export default {
  name: 'PassWord',
  components: {
    passwordDialog,
  },
  data() {
    var nameRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showName = false
      } else if (!new RegExp('[A-Z]').test(value)) {
        this.showName = false
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        this.showName = true
        callback()
      }
    }
    var passwordRule = (rule, value, callback) => {
      var Regx = /^[A-Za-z0-9*]+$/
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showPossword = false
      } else if (!new RegExp(Regx).test(value)) {
        this.showPossword = false
        callback(new Error('Only support number. capital and lower-case letter;'))
      } else {
        this.showPossword = true
        callback()
      }
    }
    var confirmPass = (rule, value, callback) => {
      var Regx = /^[A-Za-z0-9*]+$/
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showPossword = false
      } else if (!new RegExp(Regx).test(value)) {
        this.showPossword = false
        callback(new Error('Only support number. capital and lower-case letter;'))
      } else if (value !== this.resetDateForm.password) {
        this.showPossword = false
        callback(new Error('The new password and password to confirm do not matchl;'))
      } else {
        this.showPossword = true
        callback()
      }
    }
    return {
      AesPublicKey: config.AESPUBLIC_KEY,
      //  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDIufjLrteI62z7Daeb1ljYJn/2p+hDx/SKkj2Ni5PGbzB7KtVyiiXwIc4Cz53x4xjVIiQZGFXNwdHhWoqAtVjAbLuAsXqv4rMJoZmlblzvpzO/RZKSTWcyRt9cWISSneh15x6SExAypA04Bc2vJ2HOMejtyXJn1G9UxDbYuZntpwIDAQAB",
      resetDateForm: {
        accountname: '',
        password: '',
        confirmPassword: '',
      },
      rulesList: {
        accountname: [{ validator: nameRule, trigger: ['blur', 'change'] }],
        password: [{ validator: passwordRule, trigger: ['blur', 'change'] }],
        confirmPassword: [{ validator: confirmPass, trigger: 'blur' }],
      },
      showName: true,
      showPossword: true,
      showDialog: false,
      title: 'Confirm',
      content: 'Do you confirm to change the password?',
    }
  },
  mounted() {
    this.resetDateForm.accountname = this.userInfo.username
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.showDialog = true
        } else {
          return false
        }
      })
    },
    async confrom() {
      let confromData = {
        userId: this.userInfo.id,
        newPassword: createRsa(this.resetDateForm.password),
        confirmPassword: createRsa(this.resetDateForm.confirmPassword),
      }
      const { status, msg } = await $Api.user.changePwd(confromData)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$message({
          showClose: true,
          message: 'The password has been reset,please login again.',
          type: 'success',
        })
        this.showDialog = false
        this.$router.push({ name: 'login' })
      }
    },
    onCancel() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
.password {
  // .el-form-item__error{
  //   top:-20px
  // }
  .box-card {
    width: 800px;
    margin: 8% auto;
    margin-bottom: 0;
    &-header {
      display: flex;
      justify-content: space-between;
    }
    .el-card__header {
      border: none;
      padding: 16px 16px 0;
      margin-bottom: 80px;
      h4 {
        margin: 0;
        font-size: 21px;
        font-weight: 700;
      }
    }
    .el-card__body {
    }
    .el-form-item__label {
      font-size: 14px;
    }
    .redFont .el-input__inner,
    .redFont .el-input__count-inner {
      color: red;
    }
  }
  .fontS12 {
    font-size: 12px;
  }
}
.boxBtn {
  margin: 80px 16px 16px;
  text-align: right;
}
.password .el-form-item__error {
  height: 24px;
  line-height: 24px;
}
.password .el-form-item__error.fontS12 {
  color: #ccc;
}
.password .el-form-item.marginB38 {
  margin-bottom: 24px;
}
</style>
